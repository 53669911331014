.herosection .vid {
  max-width: 100%;
  height: 100vh;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .repsonsi-iframe {
    width: 100%;
    height: 40vh !important;
    object-fit: fill;
  }
}

.repsonsi-iframe {
  width: 100%;
  height: 89vh;
  object-fit: fill;
}
