.contact-us-icons svg {
  font-size: 30px;
  margin: 0 auto;
}
.contact-us-icons {
  padding: 30px;
}
.contact-block {
  padding: 10px;
  background-color: white;
  transition: 0.6s;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
}
.contact-block:hover {
  border-radius: 20px;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: var(--whiteColor);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
