.staffing_service_coverimg {
  background-size: cover !important;
  background: url("../../../assets/services/staffing.png");
  height: 35rem;
}

.staffing_css_padding {
  padding: 10px 20px 23px;
  margin-bottom: 1rem;
}
